import { Component, OnInit } from '@angular/core';
import {AuthenticationService} from '../../services/authentication.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-agency',
  templateUrl: './agency.component.html',
  styleUrls: ['./agency.component.css']
})
export class AgencyComponent implements OnInit {

  constructor(private router:Router, private authenticationService :AuthenticationService) { }

  ngOnInit() {
  }

  logoutAgency(){
    return this.authenticationService.logoutAgency(false).subscribe(data=>{
      this.authenticationService.isAuthenticated = false;
      this.router.navigateByUrl('');
    });
  }

}
