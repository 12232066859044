import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './components/login/login.component';
import { PasswordResetComponent } from './components/passwordreset/passwordreset.component';
import { MainpageComponent } from './components/mainpage/mainpage.component';
import { AgencyComponent } from './components/agency/agency.component';
import {RoutepageComponent } from './components/routepage/routepage.component';
import { PagenotfoundComponent } from './components/pagenotfound/pagenotfound.component';
import {AuthGuard} from './helpers/auth.guard';

const routes: Routes = [
  {
    path:'',
    component:RoutepageComponent


  },
  {
    path:'main',
    component:MainpageComponent


  },

  {
    path:'login',
    component:LoginComponent,

  },
  {
    path:'passwordreset',
    component:PasswordResetComponent,

  },
  {
    path:'agency',
    component:AgencyComponent,
    canActivate: [AuthGuard]
  },
  {
    path:'**',
    component:PagenotfoundComponent
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes,{
  onSameUrlNavigation :'reload'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
