import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from  '@angular/forms';
import { first } from 'rxjs/operators';

import { AuthenticationService } from '../../services/authentication.service';

@Component({
  selector: 'app-passwordreset',
  templateUrl: './passwordreset.component.html',
  styleUrls: ['./passwordreset.component.css']
})
export class PasswordResetComponent implements OnInit {
  RequestResetForm: FormGroup;
  forbiddenEmails: any;
  submitted  =  false;
  loginError:string = null;
  constructor(private router: Router,
    private formBuilder: FormBuilder,
    private authenticationService:AuthenticationService) {


     }

  ngOnInit() {
    this.RequestResetForm  =  this.formBuilder.group({
       email: [null, [Validators.required, Validators.email],this.forbiddenEmails]

   });
  }

 get f() { return this.RequestResetForm.controls; }

RequestResetUser(){
 this.submitted = true;
console.log(this.submitted);

    // stop here if form is invalid
if (this.RequestResetForm.invalid) {
    return;
}


    // this.authenticationService.login(this.f.email.value,this.f.password.value,this.f.rememberMe.value)
    //        .subscribe(
    //          data => {

    //                if(data.succeded){
    //                  this.authenticationService.isAuthenticated = true;
    //                  this.router.navigateByUrl('');
    //               }
    //               else{
    //                 this.loginError = "Incorrect email or password!";
    //               }
    //            }
    //          );


  }



}
