import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from  '@angular/forms';
import { first } from 'rxjs/operators';

import {AuthenticationService} from '../../services/authentication.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  submitted  =  false;
  loginError : string = null;
  constructor(private router: Router,
    private formBuilder: FormBuilder,
    private authenticationService:AuthenticationService) {

     }

  ngOnInit() {
    this.loginForm  =  this.formBuilder.group({
       email: ['', [Validators.required, Validators.email]],
       password: ['', [Validators.required, Validators.minLength(9)]],
       rememberMe :[false,Validators.required]
   });
  }

 get f() { return this.loginForm.controls; }

  onSubmit(){



    this.submitted = true;

    if(this.loginForm.invalid){
      return;
    }

    this.authenticationService.loginAgency(this.f.email.value,this.f.password.value,this.f.rememberMe.value)

           .subscribe(
             data => {
                   if(data.succeded){
                     this.authenticationService.isAuthenticated = true;
                     this.router.navigateByUrl('');
                   }
                   else{
                       this.loginError= "Email or password is invalid!";
                   }

               });

  }



}
