import { Injectable }  from '@angular/core';
import {AuthenticationService} from './authentication.service';

@Injectable()
export class AppInitService {

    constructor(private authenticationService : AuthenticationService) {
    }

  async  Init() {
  await  this.getloginStatus();


        return new Promise<void>((resolve, reject) => {
            console.log("AppInitService.init() called");

                resolve();


        });
    }

    async getloginStatus(){
  let authenticationStatus = await this.authenticationService.getStatus().toPromise();

      if(authenticationStatus.isAuthenticated){
        this.authenticationService.isAuthenticated = true;

      }
    }
}
