import { Injectable } from '@angular/core';

import { HttpClient, HttpHeaders,HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map} from 'rxjs/operators';
import { LoginResponse } from '../models/login-response';
import { User } from  '../models/user';
import { LogoutRequest} from  '../models/logout-request';
import {AuthenticationStatus} from  '../models/authentication-status';

const BASEURL = 'https://srv1.easyroom.xyz/api/authentication';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  httpHeaders = new HttpHeaders({
     'Access-Control-Allow-Origin': '*',
     'Content-Type' : 'application/json'
   });

    public isAuthenticated : boolean = false;
    constructor(private http: HttpClient) {

    }


    loginAgency(email, password,rememberMe): Observable <LoginResponse>{
        return this.http.post<LoginResponse>(`${BASEURL}`, { email, password,rememberMe});
    }

    getStatus():Observable<AuthenticationStatus>{
        return this.http.get<AuthenticationStatus>(`${BASEURL}`);

    }
    isLoggedIn():boolean{
      return this.isAuthenticated;
    }

    logoutAgency(allDevices:boolean){
      const deleteReq : LogoutRequest = {
          fromAllDevices : allDevices
      };
          return this.http.request('delete',`${BASEURL}`,
          {
            body :deleteReq,
             headers: this.httpHeaders
          });
    }


}
